<template>
  <div>
    <v-container fluid>

      <Overlay :overlay="overlay"/>

      <v-row justify="center">

        <v-col cols="12" lg="8">
          <v-card class="transparent" flat>

            <div class="d-flex mt-15">
              <v-file-input :error-messages="errors.excel_file"
                            accept=".xlsx, .xls"
                            background-color="white"
                            class="search-input flex-column flex"
                            filled
                            placeholder="Sélectionnez un fichier Excel..."
                            prepend-icon=""
                            prepend-inner-icon="mdi-upload"
                            required
                            rounded
                            @change="uploadFile"
                            @input="errors.excel_file = ''"
              >
              </v-file-input>
              <v-btn class="btn-search"
                     color="primary"
                     depressed
                     height="56"
                     type="submit"
                     @click="checkSms">
                <v-icon left>mdi-reload</v-icon>
                Vérifier
              </v-btn>
            </div>

            <v-card-text class="text-center">

              <div class="text-center">
                <img :src="require('@/assets/user.svg')" alt="" width="250"/>
              </div>

              <h1 :class="!$vuetify.breakpoint.mobile || 'fs-22'" class="my-4">
                Vérifier les SMS
              </h1>

              <p>
                Ce processus est utilisé pour déterminer si les messages ont été envoyés vers tous les propriétaires.
              </p>

            </v-card-text>

          </v-card>

        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>

import {HTTP} from "@/http-common";

export default {
    data() {
        return {
            overlay: false,
            excel_file: '',
            errors: {}
        }
    },
    methods: {
        uploadFile(file) {
            this.excel_file = file
        },
        checkSms() {
            this.errors = {}
            this.overlay = true

            let formData = new FormData()
            formData.append('excel_file', this.excel_file)

            HTTP.post('/check-sms', formData).then(() => {
                this.overlay = false
                this.$successMessage = "Cette opération a été termine avec succès"
            }).catch(err => {
                this.overlay = false
                this.errors = err.response.data.errors
                console.log(err)
            })
        },
    }
}
</script>

<style>
.btn-search {
    border-radius: 0 8px 8px 0;
}

.search-input v-text-field .v-input__control, .search-input.v-text-field .v-input__slot, .search-input.v-text-field fieldset {
    border-radius: 8px 0 0 8px;
}
</style>